import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Col, Row } from 'react-bootstrap';
import ScrollspyNav from 'react-scrollspy-nav';
import { NikkeSkill } from '../../../modules/nikke/common/components/nikke-skill';

const NikkeGuidesFrima: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Frima (Treasure) guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_frima_tr.webp"
            alt="Guide"
          />
        </div>
        <div className="page-details">
          <h1>Frima (Treasure) guide & review</h1>
          <h2>
            A guide & review for Frima (Treasure) in NIKKE: Goddess of Victory.
          </h2>
          <p>
            Last updated: <strong>18/08/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Frima (Treasure)" />
              <div className="employee-container for-nikke">
                <NikkeCharacter
                  mode="icon-tier"
                  slug="frima"
                  enablePopover
                  tierID={39}
                />
              </div>
              <p>
                Eye Mask? Check! Quilt? Check! Embroidered soft pillow with
                Frima's face on it? Final check complete! Frima can now sleep in
                peace because of the Commander, due to whom she was able to
                enhance her skills and unlock new, never seen before abilities!
                The new upgraded Frima has taken it on herself to become the
                best Burst 1 True Damage buffer! What she lacks now is enough
                relevant True Damage dealers to pair with her, but regardless of
                that, let's find out below what her potential really is.
              </p>
              <h5>Can you give a brief summary about this unit?</h5>
              <p>
                A new possible trend: True Damage team. The key support? Frima!
                Frima's treasure turns her into an insane support for true
                damage dealers. The key reason as to why Frima is a usable
                support in her niche of buffing true damage DPS is that she is
                not just a buffer, but a healer as well. As it stands, there are
                only 4 units in total that can deal true damage: Frima with her
                Favorite Item, Clay, Ein and Laplace (ideally with her Favorite
                Item). Of these 4 units, only Laplace and Ein can actually
                perform well as True Damage DPS. While Laplace is a good fit,
                Ein can enjoy more buffs if she is paired alongside S.Anis
                and/or Exia (with her Favorite Item) instead of Frima, and thus
                is not a good fit for the True Damage team in the current meta
                anyways.
              </p>
              <p>
                Hence, as it stands, the True Damage team is technically
                incomplete as there is currently only one DPS that fits the
                criteria. Without a doubt, we believe that Frima will have her
                say in the future, but that time may not be now. Unless or until
                Laplace decides to perform as an extremely high value unit -
                which is something that has not been the case in the last 2 Solo
                Raids (Golden Kraken and Mirror Container) - Frima will not see
                much use. As it stands, she needs to be unlocked as we patiently
                wait for the eventual True Damage DPS banner.
              </p>
              <h5>Should I get the Favorite Item for this unit?</h5>
              <p>
                Frima is a future proof Nikke at the very least. We can think of
                her as a Tove 2.0, as when Tove was released, the lack of proper
                SG DPS made her completely unusable at that time. But as more
                and more units were released, she became not just relevant, but
                has had her say in Solo Raid meta teams on multiple occasions.
                Similarly, Frima is the Tove of True Damage dealers. A healer +
                buffer hybrid, waiting to be unlocked. In today's meta there are
                only two relevant true damage DPS: Laplace and Ein, out of which
                Laplace is the only good fit for her since Ein has better
                pairings usually.
              </p>
              <p>
                We recommend getting Frima to Phase 3 Favorite Item before using
                her, as it unlocks her Skill 2's extra True Damage buff, which
                helps her to be a better buffer and reach a competitive
                standpoint in the current meta.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Kit Analysis" />
              <StaticImage
                src="../../../images/nikke/reviews/frima_treasure_1.webp"
                alt="Kit"
              />
              <h5>Phase 1 - Skill 1</h5>
              <blockquote>
                <p>■ Activates when hitting a target with Full Charge.</p>
                <p>Sleepy: DEF ▼ 4%, stacks up to 5 times for 10 sec.</p>
                <p>
                  ■ Activates when hitting a target with Full Charge for 6
                  times. Affects self when Sleepy is fully stacked.
                </p>
                <p>Wake up: Normal attacks deal true damage for 10 sec.</p>
              </blockquote>
              <p>
                Complete transformation of her Skill 1. Her DEF down on an enemy
                ability is changed into a stackable ability, which lowers enemy
                DEF on every Full Charged shot, and is called 'Sleepy'. Key
                point to note here is that Frima needs to hit the same target to
                stack 'Sleepy'. Once Sleepy is fully stacked after 5 full
                charged shots, the 6th shot activates 'Wake Up'.
              </p>
              <p>
                'Wake Up', which is the new addition to her Skill 1, gives Frima
                the ability to deal True Damage. 'Wake Up' status is also needed
                to activate the True Damage ▲ buffs present in Skill 2 and
                Burst. To get the 'Wake Up' status, Frima has to hit fully
                charge shots on the same target 6 times within 10 seconds to
                ensure that 'Sleepy' stacks are at max, and do not drop. Once
                Frima is in Wake up, the buff will countdown and you have to
                shoot 6 shots again on the same enemy to refresh the buff.
              </p>
              <p>
                True Damage is damage that completely ignores the DEF values of
                the opponent (i.e., considers the DEF value of the opponent as
                0).{' '}
              </p>
              <h5>Phase 2 - Burst Skill</h5>
              <blockquote>
                <p>■ Affects 10 enemy targets with the highest DEF.</p>
                <p>Deals 101.66% of final ATK as damage.</p>
                <p>DEF ▼ 9.86% for 10 sec.</p>
                <p>■ Affects all allies.</p>
                <p>Max HP ▲ 30.26% for 4 sec.</p>
                <p>■ Activates when in Wake Up status. Affects all allies.</p>
                <p>True Damage ▲ 49.97% for 10 sec.</p>
              </blockquote>
              <p>
                One addition, and a very important one for Frima's place in the
                current meta. If Frima is in 'Wake Up' status, she gives a
                substantial True Damage ▲ to all allies! This allows her to act
                as a Healer + Buffer, and cements her place as one for the
                future, since the present lacks a variety of True Damage
                dealers.
              </p>
              <p>
                The 'True Damage ▲' buff mimics 'Attack Damage ▲' buff (i.e.
                from Crown or Tia) and is additive to it, but is only applied to
                true damage dealers.
              </p>
              <p>
                Note: Frima needs to be in 'Wake Up' status before she bursts in
                order to give this True Damage buff. To get the 'Wake Up'
                status, Frima has to hit fully charge shots on the same target 6
                times within 10 seconds. This can mean that you might need to
                delay the first Burst until Frima has hit 6 full charged shots
                on the same target.
              </p>
              <h5>Phase 3 - Skill 2</h5>
              <blockquote>
                <p>
                  ■ Activates when attacking with Full Charge. Affects all
                  allies.
                </p>
                <p>Max HP ▲ 6.09% for 5 sec.</p>
                <p>
                  ■ Activates when attacking with Full Charge. Affects all
                  allies when in Wake Up status.
                </p>
                <p>True Damage ▲ 28.16% for 5 sec.</p>
              </blockquote>
              <p>
                Also a single addition to her skill, again an important one!
                Same as before, Frima gains a 'True Damage ▲' buff of good
                value, which is the final thing she requires to act as a proper
                true damage buffer. Same as before, you need to be in Wake Up
                status to activate this skill. This skill's True Damage buff can
                essentially be considered to have 100% uptime.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Tier List" />
              <h5>
                Campaign (LD) - <strong className="c">C</strong> | Campaign (HD)
                - <strong className="c">C</strong>
              </h5>
              <p>
                {' '}
                Frima can act as a healer for the full team, and as a buffer to
                only 2 units currently (Laplace's Favorite Item version and
                Ein), both of which display decent performances in Campaign, but
                nowhere near to what units like Red Hood, Alice, Modernia, etc
                can do. Frima also cannot compete with the variety of buffs and
                skills that the top B1s such as Liter, D:Wife or even Dorothy
                provide. While Frima is a healer + buffer, the CDR skill is
                crucial to Campaign, and as a result Frima definitely does not
                get a spot in Campaign, even if one is running LaplaceTr and
                Ein.
              </p>
              <h5>
                Boss (Solo) - <strong className="a">A</strong> | Boss (Adds) -
                <strong className="b">B</strong>
              </h5>
              <p>
                {' '}
                Against bosses, especially Solo Bosses, Frima can actually
                perform at a good level alongside Laplace. But that all depends
                on how well Laplace actually performs against the Boss. Hence,
                Frima is a situational unit as of right now, and awaits the
                release of more True Damage DPS. The ideal use case scenario of
                Frima is currently against Iron Weak Bosses, where Laplace can
                fit well as a high value DPS. Here, Frima and Rem may be used to
                buff her damage as a team 5 option. You can find more team
                examples in our team compositions section below.
              </p>
              <h5>
                PVP - <strong className="c">C</strong>
              </h5>
              <p>
                {' '}
                Frima shows similar performance to her non-treasured form in
                PvP. She does not gain any skills that can help her improve in
                PvP. If you do use her, you can just use her as a healer. But do
                know that her Burst Gen is extremely low compared to most meta
                B1 options.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Investments" />
              <h5>Gear Investments</h5>
              <div className="nikke-skills">
                <div className="nikke-skills-row over header">
                  <div className="column character">Character</div>
                  <div className="column suggestions essential">Essential</div>
                  <div className="column suggestions ideal">Ideal</div>
                  <div className="column suggestions passable">Passable</div>
                  <div className="column priority">Priority</div>
                </div>
                <div className="nikke-row">
                  <div className="nikke-skills-row over data">
                    <div className="column character">
                      <NikkeCharacter
                        slug="frima"
                        mode="icon-tier"
                        showLabel
                        enablePopover
                        onlyName
                        tierID={39}
                      />
                    </div>
                    <div className="column suggestions pve">
                      <p className="on-mobile">Essential</p>
                      <p>
                        <strong>-</strong>
                      </p>
                    </div>
                    <div className="column suggestions pvp">
                      <p className="on-mobile">Ideal</p>
                      <p>
                        <strong>1-2× Max Ammo</strong>
                      </p>
                      <p>
                        <strong>4× Charge Speed</strong>
                      </p>
                    </div>
                    <div className="column suggestions pvp">
                      <p className="on-mobile">Passable</p>
                      <p>
                        <strong>-</strong>
                      </p>
                    </div>
                    <div className="column priority">
                      <p className="on-mobile">Priority</p>
                      <p className={`prio prio-5`}>Low (PvE)</p>
                    </div>
                  </div>
                  <div className={`explanation`}>
                    <p className="on-mobile">Notes</p>
                    <p>
                      Frima does not require Overload Gear to function. However,
                      if you do Overload her, the following Priority can be
                      followed:
                    </p>
                  </div>
                </div>
              </div>
              <br></br>
              <h5>Skill Investments</h5>
              <ul>
                <li>
                  <strong>Skill 1: 1</strong>
                </li>
                <ul>
                  <li>
                    {' '}
                    Nothing in this skill provides any good offensive support
                    capabilities. Hence, we can leave this Skill at lvl1.
                  </li>
                </ul>
                <li>
                  <strong>Skill 2: 7/10</strong>
                </li>
                <ul>
                  <li>
                    {' '}
                    One of the two skills where Frima provides her True Damage
                    buff. Eventually max this out.
                  </li>
                </ul>
                <li>
                  <strong>Burst Skill: 7/10</strong>
                </li>
                <ul>
                  <li>
                    {' '}
                    One of the two skills where Frima provides her True Damage
                    buff. Eventually max this out.
                  </li>
                </ul>
              </ul>
              <br></br>
              <h5>Suggested Progression Path</h5>
              <div className="nikke-skills-tips">
                <div className="nikke-skills-row header">
                  <div className="column character">&nbsp;</div>
                  <div className="column suggestions pve">PVE Investment</div>
                  <div className="column suggestions pvp">PVP Investment</div>
                  <div className="column priority">Priority</div>
                </div>
                <div className="nikke-skills-row data">
                  <NikkeSkill char="frima" />
                </div>
              </div>
              <br />
              <br></br>
              <h5>Cube Investments</h5>
              <StaticImage
                src="../../../images/nikke/generic/cube_4_sm.png"
                alt="Guide"
              />
              <p>
                Bastion Cube should be the best cube for Frima. Resilience cube
                should be the 2nd best cube for Frima, followed by Adjutant or
                Wingman Cubes.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="PvE Team Compositions" />
              <h5>Team #1: True Damage Comp</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon-tier"
                      slug="frima"
                      enablePopover
                      tierID={39}
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="rem" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon-tier"
                      slug="laplace"
                      enablePopover
                      tierID={54}
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="placeholder-box">B3</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="dolla" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="aqua-marine-helm"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <p>
                {' '}
                For Bossing. The presumed True Damage team. As of right now, the
                B3 can be expect to be Ein, but Ein can be replaced by other DPS
                that may be performing well, or B3 units the can help Laplace
                deal more damage, such as Ludmilla: Winter Owner.
              </p>
              <h5>Team #2: Launching Rockets</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon-tier"
                      slug="frima"
                      enablePopover
                      tierID={39}
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="rem" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon-tier"
                      slug="laplace"
                      enablePopover
                      tierID={54}
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="emilia" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="dolla" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="aqua-marine-helm"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <p>
                {' '}
                For Bossing. Against bosses with 3 or more parts, Emilia shows
                immense prowess due to her increased base splash radius. This
                can allow her to slot in the B3 slot as a proper DPS, buffed by
                Rem.
              </p>
              <h5>Team #3: The Truest of Damage</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon-tier"
                      slug="frima"
                      enablePopover
                      tierID={39}
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter slug="crown" enablePopover mode="icon" />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="ein" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon-tier"
                      slug="laplace"
                      enablePopover
                      tierID={54}
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="dolla" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="d-killer-wife"
                      enablePopover
                    />
                    <NikkeCharacter mode="icon" slug="volume" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="aqua-marine-helm"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <p>
                {' '}
                For Bossing. If the boss favors true damage DPS, using Crown as
                the Burst 2 unit should be the ideal play, where Frima can help
                them deal the most damage.
              </p>
              <h5>Team #4: Extra DPS slot</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon-tier"
                      slug="frima"
                      enablePopover
                      tierID={39}
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="dolla" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="aqua-marine-helm"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon-tier"
                      slug="laplace"
                      enablePopover
                      tierID={54}
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="placeholder-box">B3</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
                    <NikkeCharacter mode="icon" slug="modernia" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                {' '}
                For Bossing. Simple team which shows that you can add an extra
                DPS alongside Frima and LaplaceTr which does not have to be a
                healer since Frima herself already fills that role!
              </p>
            </div>
            <div className="section-scroll" id="section-6">
              <SectionHeader title="Pros & Cons" />
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          Ability to heal every 5 seconds with her Max HP buff.
                        </li>
                        <li>One of the only units to have True Damage ▲.</li>
                        <li>
                          Massive value and high uptime on her True Damage
                          buffs.
                        </li>
                        <li>Auto-friendly SR unit.</li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          Expensive. Needs Phase 3 treasure to be relevant.
                        </li>
                        <li>Niche. Only buffs True Damage DPS.</li>
                        <li>
                          'Sleepy' stacks need to be stacked on the same target,
                          and the 6th shot needs to be hit on the same target
                          again to activate 'Wake Up', restricting her use to
                          Bossing only.
                        </li>
                        <li>
                          First burst needs to be delayed since you need to be
                          in Wake Up status to get the True Damage buffs.
                        </li>
                        <li>
                          If you want to heal with her constantly, you have to
                          stall her shot. If you stall her shot, you take longer
                          to get 'Wake Up'.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-6'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Kit Analysis</a>
                </li>
                <li>
                  <a href="#section-2">Tier List</a>
                </li>
                <li>
                  <a href="#section-3">Investments</a>
                </li>
                <li>
                  <a href="#section-4">PVE Team Compositions</a>
                </li>
                <li>
                  <a href="#section-6">Pros & Cons</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesFrima;

export const Head: React.FC = () => (
  <Seo
    title="Frima (Treasure) guide & review | NIKKE | Prydwen Institute"
    description="A guide & review for Frima (Treasure) in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
